import React, { Component, Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home/Home';
import Detail from './pages/Detail/Detail';
import Search from './pages/Search/Search';
import Login from './pages/Login/Login';
import AccountSettings from './pages/AccountSettings/AccountSettings';
import TvLogin from './pages/TvLogin/TvLogin';
import Price from './pages/Price/Price';
import Watch from './pages/watch/watch';
import AuthGuard from './utils/AuthGuard';
import DeleteAccount from './pages/DeleteAccount';
import PayWallGuard from './utils/paywallGuard';
// const Home = lazy(()=> import('./pages/Home/Home'))
class AppRoute extends Component {
    render() {
        return (
            <Fragment>
                
                <Routes>
                    {/*  */}
                        <Route element ={ <AuthGuard/>}>
                            <Route path="details/:slug" element={<Detail />} />
                            <Route path="search/:key" element={<Search />} />
                            <Route path="profile" element={<AccountSettings />}/>
                            <Route path="tv/setup" element={<TvLogin />} />
                            <Route path="subscricption" element={<Price />} />
                            <Route path="watch/:type/:flimId" element={<Watch/>} />
                            <Route path="delete-account" element={<DeleteAccount/>} />
                            <Route path="watch/:type/:flimId/:episodeId" element={<Watch/>} />
                        </Route>
                        <Route path="/" element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route path="login" element={<Login />} />
                        
                    {/*  */}
                </Routes>
               
            </Fragment>
        )
    }
}

export default AppRoute;