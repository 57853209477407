const initialState = {
    data: {},
    loading: false,
  };
  
  function applicationReducer(state = initialState, action) {
    switch (action.type) {
      case "PROFILE_DATA":
        return {
          ...state,
          data: { ...action.data },
        };
  
      case "SHOW_LOADER":
        return { ...state, loading: true };
  
      case "HIDE_LOADER":
        return { ...state, loading: false };
  
      case "WATCH_URL":
        return { ...state, videoInfo: action.videoInfo };
  
      case "PREVIOUS_URL":
        return { ...state, PreviousUrl: action.url };
  
      default:
        return state;
    }
  }
  
  export default applicationReducer;
  