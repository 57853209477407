import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';
import './DeleteAccount.scss'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
const DeleteAccount = () => {
  const [confirmationStep, setConfirmationStep] = useState(0);
  const [error, setError] = useState(null);
  const navigate=useNavigate()

  const handleDeleteAccount = async () => {
    const user = firebase.auth().currentUser;

    if (user) {
      try {
        // Get user authorization token
        const idToken = await user.getIdToken();

        // Make API call to delete membership
        await axios.delete(`${process.env.REACT_APP_API_URL}membership/delete`, {
          headers: {
            'apikey': process.env.REACT_APP_API_KEY,
            'authorization': idToken,
          },
        });

        // Delete user from Firebase Auth
        await user.delete();

        // Handle post-deletion tasks like redirection or showing a success message
        console.log('Account successfully deleted.');
        navigate('/login')

      } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
          setError('This operation is sensitive and requires recent authentication. Please log in again before retrying.');
        } else {
          setError('An error occurred while deleting your account.');
        }
        console.error('Error deleting account:', error);
      }
    } else {
      setError('No user is signed in.');
    }
  };

  const renderConfirmationStep = () => {
    switch (confirmationStep) {
      case 0:
        return (
          <>
            <p>Are you sure you want to delete your account?</p>
            <button className="confirm-button" onClick={() => setConfirmationStep(1)}>
              Yes
            </button>
            <button className="cancel-button" onClick={() => navigate('/profile')}>
              No, Cancel
            </button>
          </>
        );
      case 1:
        return (
          <>
            <p>This action cannot be undone. Are you absolutely certain?</p>
            <button className="confirm-button" onClick={() => setConfirmationStep(2)}>
              Yes, I'm sure
            </button>
            <button className="cancel-button" onClick={() => navigate('/profile')}>
              No, Cancel
            </button>
          </>
        );
      case 2:
        return (
          <>
            <p>Last chance! Do you really want to delete your account?</p>
            <button className="confirm-button" onClick={handleDeleteAccount}>
              Yes, Delete My Account
            </button>
            <button className="cancel-button" onClick={() => navigate('/profile')}>
              No, Cancel
            </button>
          </>
        );
      default:
        return (
          <>
            <p>Account deletion canceled.</p>
            <button className="delete-account-button" onClick={() => setConfirmationStep(0)}>
              Delete My Account
            </button>
          </>
        );
    }
  };

  return (
    <div className="delete-account-container">
      <div className="delete-account-card">
        <h2>Delete Account</h2>
        <p>
          We're sorry to see you go. Deleting your account will remove all your data
          from our system, and this action cannot be undone. If you're sure, click the
          button below.
        </p>
        {error && <p className="error-message">{error}</p>}
        {renderConfirmationStep()}
      </div>
    </div>
  );
};

export default DeleteAccount;
