import axios from 'axios'
import CryptoJS from 'crypto-js';
class ApiServices {
    getHeader() {
        const userToken = localStorage.getItem('userKey')
        if (userToken) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
            const userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            console.log(userInfo)
            return {
                headers: {
                    apikey: process.env.REACT_APP_API_KEY,
                    appversion: process.env.REACT_APP_VERSION,
                    authorization: userInfo.stsTokenManager.accessToken,
                    instanceid: userInfo.uid
                }
            }
        } else {
            return {
                headers: {
                    appversion: process.env.REACT_APP_VERSION,
                    apikey: process.env.REACT_APP_API_KEY
                }
            };
        }

    }
    callGetAPI(url) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            if (!headers.headers.authorization) {          
                //url = url.split("/web").join("/tv")                
            }
            axios.get(process.env.REACT_APP_API_URL + url, headers).then(response => {
                resolve(response)
            }).catch(error => {
                // alert("ERR::" + url + "::" + error.message)
                reject(error)
            })
        });
    }
    callDeleteAPI(url) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            axios.delete(process.env.REACT_APP_API_URL + url, headers).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    }
    MailGetAPI(url) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            axios.get('http://35.174.153.53/api/v1/course?page=1&limit=7', headers).then(response => {
                resolve(response)
            }).catch(error => {
                //alert("ERR::" + url + "::" + error.message)
                reject(error)
            })
        });
    }
    callPostAPI(url, data) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_API_URL + url, data, headers).then(response => {
                resolve(response)
            }).catch(error => {
                //alert("ERR::" + url + "::" + error.message)
                reject(error)
            })
        });
    }
    callPutAPI(url, data) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            axios.put(process.env.REACT_APP_API_URL + url, data, headers).then(response => {
                resolve(response)
            }).catch(error => {
                //alert("ERR::" + url + "::" + error.message)
                reject(error)
            })
        });
    }

    validateUser() {
        const headers = this.getHeader();
        axios.get(process.env.REACT_APP_API_URL + 'membership/validate', headers).then(response => {
            if (response.status === 200 && response.data.valid) {
                return response.data.data.premiumuser === 1 ? true : false;
            } else {
                return false;
            }
        }).catch(error => {
            //alert("ERR::membership/validate::" + error.message)
            return false;
        })
    }
   


    getHeaderV2() {
        const userToken = localStorage.getItem('userKey')
        if (userToken) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
            const userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            console.log(userInfo)
            return {
                headers: {
                    apikey: process.env.REACT_APP_API_KEY_V2,
                    appversion: process.env.REACT_APP_VERSION_V2,
                    authorization: userInfo.stsTokenManager.accessToken,
                    instanceid: userInfo.uid
                }
            }
        } else {
            return {
                headers: {
                    appversion: process.env.REACT_APP_VERSION_V2,
                    apikey: process.env.REACT_APP_API_KEY_V2
                }
            };
        }

    }
    callGetAPIV2(url) {
        const headersV2 = this.getHeaderV2();
        return new Promise((resolve, reject) => {
            axios.get(process.env.REACT_APP_API_URL_V2 + url, headersV2).then(response => {
                resolve(response)
            }).catch(error => {
                //alert("ERR::V2::" + url + "::" + error.message)
                reject(error)
            })
        });
    }
    MailGetAPIV2(url) {
        const headersV2 = this.getHeaderV2();
        return new Promise((resolve, reject) => {
            axios.get('http://35.174.153.53/api/v1/course?page=1&limit=7', headersV2).then(response => {
                resolve(response)
            }).catch(error => {
                //alert("ERR::V2::" + url + "::" + error.message)
                reject(error)
            })
        });
    }
    callPostAPIV2(url, data) {
        const headersV2 = this.getHeaderV2();
        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_API_URL_V2 + url, data, headersV2).then(response => {
                resolve(response)
            }).catch(error => {
                //alert("ERR::V2::" + url + "::" + error.message)
                reject(error)
            })
        });
    }
    callPutAPIV2(url, data) {
        const headersV2 = this.getHeaderV2();
        return new Promise((resolve, reject) => {
            axios.put(process.env.REACT_APP_API_URL_V2 + url, data, headersV2).then(response => {
                resolve(response)
            }).catch(error => {
                //alert("ERR::V2::" + url + "::" + error.message)
                reject(error)
            })
        });
    }

    validateUserV2() {
        const headersV2 = this.getHeaderV2();
        return new Promise((resolve, reject) => {
            axios.get(process.env.REACT_APP_API_URL_V2 + 'membership/validate', headersV2).then(response => {
                if (response.status === 200 && response.data.valid) {
                    return response.data.data.premiumuser === 1 ? true : false;
                } else {
                    return false;
                }
            }).catch(error => {
                //alert("ERR::V2::membership/validate::" + error.message)
                return false;
            })
        });
    }
}

export default new ApiServices();