import React, {Component} from 'react'
import './search.scss'
import ApiServices from '../../services/apiServices';
import withRouter from '../../services/withRouter';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_keyword: '',
      result: []
    }
  }
  componentWillMount() {
    const {key} = this.props.params;
    this.getsearchResult(key);
  }
  componentWillReceiveProps(nextProps){   
    console.log("receive>>",nextProps) 
    const {key} = nextProps.params;
    this.getsearchResult(key);
  }
  getsearchResult(keyword) {
    ApiServices.callGetAPI('movies/search/tv/'+keyword)
    .then( result => {
      if(result.status === 200 && result.data.valid) {
        this.setState({
          result: result.data.data
        })
      }
    }).catch(error=> {
      console.log(error);
    });
    }
  render() {
    return(
      <div className='main-body search-body'>
        <div className='card-row'>
                        <div className='card-header flex align-items-center justify-content-between'>
                            <h2 className='semi-bold'>Search result of '{this.props.params.key}'</h2>
                        </div>
                        {
                          this.state.result.length> 0 ? 
                          <div className='mySwiper flex search'>
                            {
                            this.state.result.map((video, i)=> (
                              
                              <div className='swiper-slide'>
                                  <Link to={'/details/'+ video.filmid}>
                                  <div className='card-thumb-top relative flex cursor'>
                                      <img className='card-thumb' src={video.image[0]} 
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src="https://d12it2ctp7su6p.cloudfront.net/shortflix-web-static/image/SF_logo_Black_BG_(960x540).png";
                                      }} alt="" />
                                      {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                      {/* <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg> */}
                                      {/* <span className='continue-border'><span style={{ width: "10%" }}></span></span> */}
                                      {
                                    video?.contentadvisory ? 
                                        <svg className='play-icon left' xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M6 22q-.825 0-1.412-.587Q4 20.825 4 20V10q0-.825.588-1.413Q5.175 8 6 8h1V6q0-2.075 1.463-3.538Q9.925 1 12 1t3.538 1.462Q17 3.925 17 6v2h1q.825 0 1.413.587Q20 9.175 20 10v10q0 .825-.587 1.413Q18.825 22 18 22Zm6-5q.825 0 1.413-.587Q14 15.825 14 15q0-.825-.587-1.413Q12.825 13 12 13q-.825 0-1.412.587Q10 14.175 10 15q0 .825.588 1.413Q11.175 17 12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6Z"/></svg>
                                        :
                                        ''
                                    }
                                  </div>
                                  <div className='relative'>
                                      <h4 className='card-title text-control chapter-title cursor'>{video.title}</h4>
                                  </div>
                                  </Link>
                              </div>
                            
                            ))
                            }
                          </div>
                          

                        : 
                        <div className='no-result'>
                          <h1>No result Found</h1>
                        </div>
                        }
                        
                    </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({ loading: state.application.loading });
export default withRouter(connect(mapStateToProps)(Search))