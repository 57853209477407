import { createStore, compose, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import rootReducer from "../src/reducer";

// Set up Redux DevTools or fall back to a no-op function if not available
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  return createStore(
    rootReducer, // Root reducer that combines all your app's reducers
    composeEnhancer(applyMiddleware(thunk)) // Apply middleware and enhancers
  );
}
