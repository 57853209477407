import { Outlet, Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const AuthGuard = () => {
    // localStorage.setItem('userKey', data)
    let location = useLocation();
    localStorage.setItem('path', location.pathname);
    const auth = {token: localStorage.getItem('userKey') ? true: false};
    return (
        // auth.token ? <Outlet/> : <Navigate to="/login"/>
        auth.token ? <Outlet/> : <Outlet/>
    )
};

export default AuthGuard;