import React, { Component} from 'react'
import './tvLogin.scss';
import logo from '../../assets/images/logo.png'
import ApiServices from '../../services/apiServices';
import withRouter from '../../services/withRouter';
import { connect } from "react-redux";
import CryptoJS from 'crypto-js';
import { showLoader, hideLoader, PreviousUrl } from "../../action/application";
import { toast } from 'react-toastify';
class TvLogin extends Component {
    userInfo;
    constructor(props) {
        super(props);
        this.state = {
            tvcode: '',
            loginStatus: false
        }
        this.props.dispatch( showLoader() )
    }
    componentWillMount() {
        //alert("TVLogin")
        localStorage.removeItem('path');
        if(localStorage.getItem('userKey')) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
            this.userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          } 
        ApiServices.callGetAPI('membership/validate').then(result=>{
              if(result.status === 200 && result.data.valid) {
                  if(!result.data.data.premiumuser) {
                    this.props.dispatch( PreviousUrl(window.location.pathname) );
                    this.props.navigate('/subscricption');
                    this.props.dispatch( hideLoader() );
                  }else {
                    this.props.dispatch( hideLoader() );
                  }
                  
              } 
          }).catch(error=>{
              console.log(error);
              this.props.navigate('/');
          });
    }
    updateOtp($event) {
        console.log($event.keyCode)
        const otpcomponent = document.querySelectorAll('.otp-value');
        let otpvalue = ''
        otpcomponent.forEach((key, index) => {
            otpvalue = otpvalue + key['value'];
          });
        const datalen = otpvalue.length;
        if (datalen < 6 && datalen > 0) {
            if ($event.keyCode === 8) {
                otpcomponent[datalen-1].focus();
            }else {
                otpcomponent[datalen].focus();
            }
            
        }
        this.setState({tvcode: otpvalue, loginStatus: false})
    }
    clearOtp() {
        const otpcomponent = document.querySelectorAll('.otp-value');
        otpcomponent.forEach((key, index) => {
            // otpvalue = otpvalue + key['value'];
            otpcomponent[index].value = '';
            
          });
          otpcomponent[0].focus();
    }
    deviceVerify() {
        ApiServices.callPostAPI('device/verifycode',{
            name: this.userInfo.displayName ? this.userInfo.displayName: '',
            contact: this.userInfo.phoneNumber ? this.userInfo.phoneNumber: '',
            email: this.userInfo.email ? this.userInfo.email: '',
            code: this.state.tvcode
        }).then(result=> {
            if(result.status === 200 && result.data.valid) {
                console.log(result);
                toast.error('Verified Successfully', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                this.props.navigate('/'); 
            } else {
                this.setState({
                    loginStatus: true
                })
                toast.error('Please Enter the Valid Code', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                this.clearOtp();
            }
        }).catch(error=>{ 
            console.log(error);
            window.history.back();
          })
    }
    render() {
        return (
            <div className='login-main flex justify-content-center'>
                <div className='login-main-inner'>
                    <img src={logo} className="App-logo" alt="logo" />
                    <h4 className='verify'>Enter the code you see in your TV screen and click 'Continue'</h4>
                    <form >
                        <div className='form-group verify'>
                            <div className='otp-input flex justify-content-center full-width'>
                                <input type="text" maxlength="1"   onKeyUp={(e)=> this.updateOtp(e)} autoFocus="" className="single-otp-input otp-value semi-bold otp-value" />
                                <input type="text" maxlength="1"   onKeyUp={(e)=> this.updateOtp(e)} className="single-otp-input otp-value semi-bold otp-value" />
                                <input type="text" maxlength="1"  onKeyUp={(e)=> this.updateOtp(e)} className="single-otp-input otp-value semi-bold otp-value" />
                                <input type="text" maxlength="1" onKeyUp={(e)=> this.updateOtp(e)}   className="single-otp-input otp-value semi-bold otp-value" />
                                <input type="text" maxlength="1" onKeyUp={(e)=> this.updateOtp(e)}   className="single-otp-input otp-value semi-bold otp-value" />
                                <input type="text" maxlength="1" onKeyUp={(e)=> this.updateOtp(e)}   className="single-otp-input otp-value semi-bold otp-value" />
                            </div>
                        </div>
                        <div className='code-counter'>
                            <div className="link-header text-center">
                                {/* { this.state.loginStatus ? <span>Please Enter the Valid Code</span>: ''} */}
                                {/* Resend text with Timer Start */}
                                {/* <span className="normal medium otp-text">Resend code in</span>
                                <span className="normal medium left-space strong-clr">
                                    <spam clspamss="input-msg no-events">20 Sec</spam>
                                </span> */}
                                {/* Resend text with Timer End */}
                                {/* Resend text with Count Start */}
                                {/* <span className="semi-bold normal">
                                    <span className="input-msg otp cursor change">Resend Code</span>
                                </span>
                                <span className="otp-text normal left-space strong-clr">(1/5)</span> */}
                                {/* Resend text with Count End */}
                            </div>
                        </div>
                        <button type='button' className='button verify' onClick={()=> this.deviceVerify()}>Continue</button>
                    </form>
                </div>
            </div>
          )
    }
}

const mapStateToProps = state => ({ 
    loading: state.application.loading,
    PreviousUrl: state.application.PreviousUrl
    });
export default withRouter(connect(mapStateToProps)(TvLogin))
