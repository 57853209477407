import './header.scss';
import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/logo.png';
import profile from '../../assets/images/profile.svg';
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import CryptoJS from 'crypto-js';
import firebase from 'firebase/compat/app';
import ApiServices from '../../services/apiServices';

function Header(props) {
  let userInfo = '';
  const [color, setColor] = useState(false);
  const url = 'https://lh3.googleusercontent.com/a/AItbvmm_qsCQ7-c3I8sWocPciEYjgJj1oW_7QRZdVZQ=s96-c';
  const navigate = useNavigate();

  if (localStorage.getItem('userKey')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
    userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log('userInfo', userInfo);
  } else {
    //return
  }

  useEffect(() => {
    ApiServices.callGetAPI('membership/validate')
      .then(result => {
        const subscribeButton = document.getElementsByClassName("subscribebtn")[0];
        if (result.status === 200 && result.data.valid) {
          const membership = result.data.data.premiumuser > 0;
          if (subscribeButton) {
            subscribeButton.style.display = membership ? "none" : "";
          }
          setColor(membership);
          console.log('member', result, membership);
        } else {
          setColor(false);
          if (subscribeButton) {
            subscribeButton.style.display = "";
          }
        }
      })
      .catch(error => {
        const subscribeButton = document.getElementsByClassName("subscribebtn")[0];
        if (subscribeButton) {
          subscribeButton.style.display = "";
        }
        console.error('API error:', error);
      });
  }, []);

  return (
    <header className='header flex align-items-center justify-content-between'>
      <div className='heder-left flex align-items-center'>
        <a href="/" className='flex'><img src={logo} className="App-logo" alt="logo" /></a>
      </div>
      <div className='heder-right flex align-items-center'>
        <div className='heder-search'>
          <input type="text" placeholder='Search' id="search" className='header-search-input cursor' onKeyUp={(e) => {
            if (e.target.value.length > 1 && (e.code === "Enter" || e.keyCode === 13)) {
              navigate("/search/" + e.target.value);
              document.getElementById('search').value = '';
            }
          }} />
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m19.55 20.575-6.3-6.275q-.75.625-1.725.975-.975.35-2 .35-2.575 0-4.35-1.775Q3.4 12.075 3.4 9.5q0-2.55 1.775-4.338 1.775-1.787 4.35-1.787 2.55 0 4.325 1.775 1.775 1.775 1.775 4.35 0 1.075-.35 2.05-.35.975-.95 1.7l6.275 6.275Zm-10.025-6.45q1.925 0 3.263-1.35 1.337-1.35 1.337-3.275 0-1.925-1.337-3.275-1.338-1.35-3.263-1.35-1.95 0-3.287 1.35Q4.9 7.575 4.9 9.5q0 1.925 1.338 3.275 1.337 1.35 3.287 1.35Z" /></svg>
        </div>
        <button className='button flex align-items-center justify-content-center subscribebtn' style={{ borderRadius: "18px", marginRight: "20px", display: "none" }} onClick={() => {
          navigate('/subscricption');
        }}>
          Subscribe
        </button>
        {userInfo ?
          <div className='mini-profile flex align-items-center relative'>
            <span className='mini-pic flex cursor'>
              {userInfo.photoURL ?
                <img src={userInfo.photoURL} onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = profile;
                }} className="App-profile" alt="profile pic" /> :
                <img src={profile} className="App-profile" alt="default" />
              }
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9.635" viewBox="0 0 16.441 9.635" className="mini-arrow"><path id="Path_1717" data-name="Path 1717" d="M-16375.842,8268.459l6.807,6.807,6.807-6.807" transform="translate(16377.256 -8267.045)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"></path></svg>
            </span>
            <div className='mini-profile-down'>
              <div className='profile-down-inner'>
                <div className="user-detail-list flex align-items-center ">
                  <img src={userInfo.photoURL ? userInfo.photoURL : profile} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = profile;
                  }} className="App-profile" alt="profile pic" />
                  <div className="user-info">
                    <div className="user-info-list">
                      <span className="username">{userInfo.displayName ? userInfo.displayName : ''}</span>
                      <div className="user-no none">9876543210</div>
                    </div>
                  </div>
                </div>
                <div className='account-details'>
                  <ul>
                    <li className='flex align-items-center relative'>
                      <Link to={'/profile'}>
                        <span className='flex align-items-center'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' height="24" width="24"><path d="m9.25 22-.4-3.2q-.325-.125-.612-.3-.288-.175-.563-.375L4.7 19.375l-2.75-4.75 2.575-1.95Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337L1.95 9.375l2.75-4.75 2.975 1.25q.275-.2.575-.375.3-.175.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3.287.175.562.375l2.975-1.25 2.75 4.75-2.575 1.95q.025.175.025.337v.675q0 .163-.05.338l2.575 1.95-2.75 4.75-2.95-1.25q-.275.2-.575.375-.3.175-.6.3l-.4 3.2Zm2.8-6.5q1.45 0 2.475-1.025Q15.55 13.45 15.55 12q0-1.45-1.025-2.475Q13.5 8.5 12.05 8.5q-1.475 0-2.488 1.025Q8.55 10.55 8.55 12q0 1.45 1.012 2.475Q10.575 15.5 12.05 15.5Z" /></svg>
                          Account & Details
                        </span>
                      </Link>
                    </li>
                    {color === true ?
                      <li className='flex align-items-center relative'>
                        <Link to={'/tv/setup'}>
                          <span className='flex align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24px" width="24px" version="1.1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512">
                              <g>
                                <g>
                                  <path d="m241.4,129.3c-0.7-1.7-1.7-3.4-2.9-4.9-0.2-0.3-0.4-0.5-0.6-0.7 1.1,1.8 2.3,3.7 3.5,5.6z" />
                                  <path d="m461.2,369.5h-408.4v-316.7h408.3v316.7h0.1zm-125.2,91.7h-158c10.2-29.3 41.7-50.8 79-50.8 37.2,0 68.8,21.4 79,50.8zm126-449.2h-410c-22.1,0-40,17.9-40,40v318.4c0,22.1 17.9,40 40,40h109.9c-17.6,19.4-28.2,44.2-28.2,71.2 0,11.3 9.1,20.4 20.4,20.4h205.7c11.3,0 20.4-9.1 20.4-20.4 0-27-10.6-51.8-28.2-71.2h110c22.1,0 40-17.9 40-40v-318.4c0-22.1-17.9-40-40-40z" />
                                  <path d="m117.4,227.1l92.4,74.1c8.8,7 21.6,5.7 28.7-3.2 7-8.8 5.6-21.7-3.2-28.7l-47.1-37.7h195.5c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-195.4l47.1-37.7c8.8-7 10.2-19.9 3.2-28.7-7.1-8.8-19.9-10.2-28.7-3.2l-92.4,74.1c-11.3,11.1-10.7,21.2-0.1,31.8z" />
                                </g>
                              </g>
                            </svg>
                            Tv login
                          </span>
                        </Link>
                      </li> : ''
                    }
                    {/* <li className='flex align-items-center relative'>
                      <Link to={'/delete-account'}>
                        <span className='flex align-items-center'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' height="24" width="24"><path d="M7 21q-.825 0-1.413-.587Q5 19.825 5 19V5q0-.825.587-1.413Q6.175 3 7 3h10q.825 0 1.413.587Q19 4.175 19 5v14q0 .825-.587 1.413Q17.825 21 17 21Zm10-2V5H7v14ZM8.5 16h2v-8h-2Zm5 0h2v-8h-2ZM5 5v14V5Z" /></svg>
                          Delete Account
                        </span>
                      </Link>
                    </li> */}
                    <li className='flex align-items-center relative'>
                      <a href='/' className='flex align-items-center' onClick={() => { firebase.auth().signOut() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' height="24" width="24"><path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h7v2H5v14h7v2Zm11-4-1.375-1.45 2.55-2.55H9v-2h8.175l-2.55-2.55L16 7l5 5Z" /></svg>
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          : <div className='mini-profile flex align-items-center relative'>
            <span className='mini-pic flex cursor'>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9.635" viewBox="0 0 16.441 9.635" className="mini-arrow"><path id="Path_1717" data-name="Path 1717" d="M-16375.842,8268.459l6.807,6.807,6.807-6.807" transform="translate(16377.256 -8267.045)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"></path></svg>
            </span>
            <Link to={'/login'}><span className='menu'>Sign in</span></Link>
          </div>
        }
      </div>
    </header>
  )
}

export default Header;
