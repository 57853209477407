import React, { Component } from 'react'
import './card.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation,FreeMode } from "swiper/modules";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import { setUrl, PreviousUrl } from "../../action/application";
import { connect } from "react-redux";
import withRouter from '../../services/withRouter';
class Card extends Component {

    secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h === 1 ? " h, " : " h, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " m, " : " m, ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " s" : " s") : "";
        return hDisplay + mDisplay + sDisplay;
    }
    watchvideo(video, active) {
        this.props.dispatch(PreviousUrl(window.location.pathname));
        if (active) {
            this.props.dispatch(setUrl(video));

            this.props.navigate(`/watch/series/${video.filmid}/short${video.sequence}`);
        } else {
            this.props.navigate('/subscricption');
        }

    }
    shareVideo() {
        window.open("https://api.whatsapp.com/send?text=" + window.location.href);
    }
    render() {
        switch (this.props.cardType) {
            case 'video':
                if(this.props.cardData.genre === "Top_Shorts" || this.props.cardData.genre === "Top  Shorts" || this.props.cardData.genre === "Top Shorts"){
                    return (
                        <div className='card-row'>
                            <div className='card-header flex align-items-center justify-content-between'>
                                <h2 className='semi-bold'>{this.props.cardData.genre}</h2>
                            </div>
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={10}
                                navigation={true}
                                speed={500}
                                freeMode={true}
                                breakpoints={{
                                    480: {
                                        slidesPerView: 4,
                                        spaceBetween: 4
                                    },
                                    560: {
                                        slidesPerView: 4,
                                        spaceBetween: 4,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 4,
                                        freeMode: false
                                    },
                                    991: {
                                        slidesPerView: 7,
                                        spaceBetween: 22,
                                        freeMode: false
                                    },
                                    1600: {
                                        slidesPerView: 8,
                                        spaceBetween: 22,
                                        freeMode: false
                                    }
                                }}
                                modules={[Navigation, FreeMode]}
                                className="mySwiper"
                            >
                                {
                                    this.props.cardData.movies.map((video, i) => (
                                        <SwiperSlide className='cursor'>
                                            <Link to={'/details/' + video.filmid}>
                                                <div className='card-thumb-topV relative flex cursor'>
                                                    <img className='card-thumbV' src={(video.thumbnail)[0]} alt=""
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://d12it2ctp7su6p.cloudfront.net/shortflix-web-static/image/SF_logo_Black_BG_(960x540).png";
                                                        }} />
                                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                                    {/* {
                                        video?.contentadvisory ? 
                                            <svg className='play-icon left' xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M6 22q-.825 0-1.412-.587Q4 20.825 4 20V10q0-.825.588-1.413Q5.175 8 6 8h1V6q0-2.075 1.463-3.538Q9.925 1 12 1t3.538 1.462Q17 3.925 17 6v2h1q.825 0 1.413.587Q20 9.175 20 10v10q0 .825-.587 1.413Q18.825 22 18 22Zm6-5q.825 0 1.413-.587Q14 15.825 14 15q0-.825-.587-1.413Q12.825 13 12 13q-.825 0-1.412.587Q10 14.175 10 15q0 .825.588 1.413Q11.175 17 12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6Z"/></svg>
                                            :
                                            ''
                                        } */}
                                                </div>
                                            </Link>
                                            <h4 className='card-title text-control'>{video.title}</h4>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    );
                }else{
                    return (
                        <div className='card-row'>
                            <div className='card-header flex align-items-center justify-content-between'>
                                <h2 className='semi-bold'>{this.props.cardData.genre}</h2>
                            </div>
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={10}
                                navigation={true}
                                speed={500}
                                freeMode={true}
                                breakpoints={{
                                    480: {
                                        slidesPerView: 2,
                                        spaceBetween: 16
                                    },
                                    560: {
                                        slidesPerView: 3,
                                        spaceBetween: 16,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 16,
                                        freeMode: false
                                    },
                                    991: {
                                        slidesPerView: 4,
                                        spaceBetween: 16,
                                        freeMode: false
                                    },
                                    1600: {
                                        slidesPerView: 5,
                                        spaceBetween: 16,
                                        freeMode: false
                                    }
                                }}
                                modules={[Navigation, FreeMode]}
                                className="mySwiper"
                            >
                                {
                                    this.props.cardData.movies.map((video, i) => (
                                        <SwiperSlide className='cursor'>
                                            <Link to={'/details/' + video.filmid}>
                                                <div className='card-thumb-top relative flex cursor'>
                                                    <img className='card-thumb' src={(video.image || video.thumbnail)[0]} alt=""
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://d12it2ctp7su6p.cloudfront.net/shortflix-web-static/image/SF_logo_Black_BG_(960x540).png";
                                                        }} />
                                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                                    {/* {
                                        video?.contentadvisory ? 
                                            <svg className='play-icon left' xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M6 22q-.825 0-1.412-.587Q4 20.825 4 20V10q0-.825.588-1.413Q5.175 8 6 8h1V6q0-2.075 1.463-3.538Q9.925 1 12 1t3.538 1.462Q17 3.925 17 6v2h1q.825 0 1.413.587Q20 9.175 20 10v10q0 .825-.587 1.413Q18.825 22 18 22Zm6-5q.825 0 1.413-.587Q14 15.825 14 15q0-.825-.587-1.413Q12.825 13 12 13q-.825 0-1.412.587Q10 14.175 10 15q0 .825.588 1.413Q11.175 17 12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6Z"/></svg>
                                            :
                                            ''
                                        } */}
                                                </div>
                                            </Link>
                                            <h4 className='card-title text-control'>{video.title}</h4>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    );
                }
                
                break;
            case 'episode':
                return (
                    <div className='card-row'>
                        <div className='card-header flex align-items-center justify-content-between'>
                            <h2 className='semi-bold'>Episodes</h2>
                        </div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation={true}
                            speed={500}
                            freeMode={true}
                            breakpoints={{
                                480: {
                                    slidesPerView: 2,
                                    spaceBetween: 16
                                },
                                560: {
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                    freeMode: false
                                },
                                991: {
                                    slidesPerView: 4,
                                    spaceBetween: 16,
                                    freeMode: false
                                },
                                1600: {
                                    slidesPerView: 5,
                                    spaceBetween: 16,
                                    freeMode: false
                                }
                            }}
                            modules={[Navigation, FreeMode]}
                            className="mySwiper">
                            {this.props.cardData.movies.map((video, i) => (
                                <SwiperSlide>
                                    <div className='card-thumb-top relative flex cursor' onClick={() => this.watchvideo(video, this.props.cardData.memberShip)}>
                                        <img className='card-thumb' src={video.episodethumbnail[0]} alt="" />
                                        <span className='continue-border'><span style={{ width: (video.watchpercentage || video.watchedpercentage) + "%" }}></span></span>
                                    </div>
                                    <div className='relative'>
                                        <h4 className='card-title text-control chapter-title cursor'>{i + 1}. {video.title} </h4>
                                        <ul className="flex badges">
                                            <li><span className="badge plain medium">{video.durationtext} </span></li>
                                            <li><span className="badge plain medium">{video.releaseyear}</span></li>
                                        </ul>
                                        <svg className='share-icon cursor' onClick={() => { this.shareVideo() }} xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m5.425 10.625 2.2.925q.4-.825.863-1.575.462-.75 1.012-1.5L8.05 8.2q-.1-.025-.162 0-.063.025-.138.075Zm3.4 1.875 2.825 2.825q1.125-.475 2.325-1.3 1.2-.825 2.275-1.9 1.675-1.675 2.6-3.663.925-1.987.925-4.087-2.1 0-4.087.912Q13.7 6.2 12 7.9q-1.075 1.075-1.9 2.275T8.825 12.5Zm4.8-2q-.5-.525-.5-1.25t.5-1.225q.525-.5 1.262-.5.738 0 1.238.5t.5 1.225q0 .725-.5 1.25-.5.5-1.238.5-.737 0-1.262-.5Zm-.1 8.225L15.85 16.4q.075-.075.1-.138.025-.062 0-.162l-.3-1.45q-.725.55-1.487 1-.763.45-1.563.875ZM21.125 3q.325 2.9-.675 5.487-1 2.588-3.3 4.888l-.088.087-.087.088.45 2.25q.075.45-.05.888-.125.437-.45.737L13 21.35l-1.875-4.425L7.2 13l-4.4-1.875L6.7 7.2q.3-.3.738-.438.437-.137.887-.062l2.3.475q.05-.05.075-.087.025-.038.075-.088 2.3-2.3 4.888-3.313Q18.25 2.675 21.125 3Zm-16.6 13.075q.725-.725 1.775-.738 1.05-.012 1.775.738.75.725.737 1.775-.012 1.05-.737 1.775-.575.575-1.862.975-1.288.4-3.338.65.25-2.025.663-3.312.412-1.288.987-1.863Zm1.075 1.05q-.275.275-.525.937-.25.663-.35 1.338.7-.075 1.35-.325.65-.25.925-.525.3-.3.312-.725.013-.425-.287-.725t-.713-.288q-.412.013-.712.313Z" /></svg>
                                    </div>
                                    <p className='chapter-desc text-control-three'>{video.description}</p>
                                </SwiperSlide>
                            ))}


                        </Swiper>
                    </div>
                );
                break;
            case 'continue':
                return (
                    <div className='card-row'>
                        <div className='card-header flex align-items-center justify-content-between'>
                            <h2 className='semi-bold'>Continue Watch</h2>
                        </div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation={true}
                            speed={500}
                            freeMode={true}
                            breakpoints={{
                                480: {
                                    slidesPerView: 2,
                                    spaceBetween: 16
                                },
                                560: {
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                    freeMode: false
                                },
                                991: {
                                    slidesPerView: 4,
                                    spaceBetween: 16,
                                    freeMode: false
                                },
                                1600: {
                                    slidesPerView: 5,
                                    spaceBetween: 16,
                                    freeMode: false
                                }
                            }}
                            loop={false}
                            modules={[Navigation, FreeMode]}
                            className="mySwiper"
                        >
                            {
                                this.props.cardData.movies.map((video, i) => (
                                    <SwiperSlide>
                                        <Link to={'/details/' + video.filmid}>
                                            <div className='card-thumb-top relative flex cursor'>
                                                <img className='card-thumb' src={(video.image || video.thumbnail)[0]} alt=""
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = "https://d12it2ctp7su6p.cloudfront.net/shortflix-web-static/image/SF_logo_Black_BG_(960x540).png";
                                                    }} />

                                                <span className='continue-border'><span style={{ width: (video.watchpercentage || video.watchedpercentage) + "%" }}></span></span>
                                            </div>
                                            <div className='relative'>
                                                <h4 className='card-title text-control chapter-title cursor'>{video.title}</h4>

                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                )
                break;
            case 'search':
                return (
                    <div className='card-row'>
                        <div className='card-header flex align-items-center justify-content-between'>
                            <h2 className='semi-bold'>Search result of 'jack'</h2>
                        </div>
                        <div className='mySwiper flex search'>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/idakini/idakini-idakini-3.png" alt="" />
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "70%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>Back Then</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/kannagi/kannagi-1.png" alt="" />
                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "40%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>Bossed</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/kalyanaraman/kalyanaraman-1.png" alt="" />
                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "10%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>First Meet</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/thadayam/thadayam-1.png" alt="" />
                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "35%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>Faults remain Silent</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/aandhai/aandhai-1.png" alt="" />
                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "10%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>The Chase</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/bodybhaskar/bodybhaskar-1.png" alt="" />
                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "20%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>A blot from the Blue</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/unardhal/unardhal-1.png" alt="" />
                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "70%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>Behind the Mask</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/kannamma/kannamma-1.png" alt="" />
                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "90%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>Misery Loves Company</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='swiper-slide'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <img className='card-thumb' src="https://d12it2ctp7su6p.cloudfront.net/attar/attar-1.png" alt="" />
                                    {/* <span className='card-thumb skeleton-animation skeleton-card'></span> */}
                                    <svg className='play-icon cursor' xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M15.917 27.208 27.208 20l-11.291-7.208ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Zm0-2.792q5.792 0 9.833-4.042 4.042-4.041 4.042-9.833t-4.042-9.833Q25.792 6.125 20 6.125t-9.833 4.042Q6.125 14.208 6.125 20t4.042 9.833q4.041 4.042 9.833 4.042ZM20 20Z" /></svg>
                                    <span className='continue-border'><span style={{ width: "10%" }}></span></span>
                                </div>
                                <div className='relative'>
                                    <h4 className='card-title text-control chapter-title cursor'>Lorem ipsum dolor</h4>
                                    <ul className="flex badges">
                                        <li><span className="badge plain medium">30m 57s </span></li>
                                        <li><span className="badge plain medium">2022</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'skeleton':
                return (
                    <div className='card-row'>
                        <div className='card-header flex align-items-center justify-content-between'>
                            <h2 className='semi-bold skeleton-animation skeleton-title'></h2>
                        </div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation={true}
                            speed={500}
                            freeMode={true}
                            breakpoints={{
                                480: {
                                    slidesPerView: 2,
                                    spaceBetween: 16
                                },
                                560: {
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                    freeMode: false
                                },
                                991: {
                                    slidesPerView: 4,
                                    spaceBetween: 16,
                                    freeMode: false
                                },
                                1600: {
                                    slidesPerView: 5,
                                    spaceBetween: 16,
                                    freeMode: false
                                }
                            }}
                            loop={true}
                            modules={[Navigation, FreeMode]}
                            className="mySwiper"
                        >
                            <SwiperSlide className='cursor'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <span className='card-thumb skeleton-animation skeleton-card'></span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className='cursor'>
                                <div className='card-thumb-top relative flex cursor'>
                                    <span className='card-thumb skeleton-animation skeleton-card'></span>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                );
            default:
                break;
        }

    }
}

const mapStateToProps = state => ({ loading: state.application.loading });
export default withRouter(connect(mapStateToProps)(Card))