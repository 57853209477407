import React, { useEffect } from 'react';
import './login.css';
import logo from '../../assets/images/logo.png';
import 'react-phone-input-2/lib/style.css';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import { useLocation } from 'react-router-dom';

function Login(props) {
    const location = useLocation(); // Get current location

    useEffect(() => {
        setTimeout(() => {
            const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

            ui.start('.firebase-auth-container', {
                signInOptions: [
                    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    firebase.auth.EmailAuthProvider.PROVIDER_ID,
                ],
                signInFlow: 'popup',
                privacyPolicyUrl: 'https://www.shortflixindia.com/privacy',
                signInSuccessUrl: '/',
                redirectUrl: '/'
            });
        },100); // Delay by 100ms to ensure full component load
    }, [location]);  // Re-run effect whenever location changes

    return (
        <div className='login-main flex justify-content-center' key={location.key}>
            <div className='login-main-inner'>
                <img src={logo} className="App-logo" alt="logo" />
                <h3>Sign in to your account</h3>
                <div className='firebase-auth-container'></div>
            </div>
        </div>
    );
}

export default Login;
