import './App.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import AppRoute from './route';
import AppLoader from './components/loader/loader';
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { onAuthStateChanged } from 'firebase/auth';
import CryptoJS from 'crypto-js';
import logo from './assets/images/logo.png';
import app_store from './assets/images/ios-store.png';
import play_store from './assets/images/android-store.png';
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App(props) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBWU1DmgNNBg84sPQFI2wTfpBXyb3BqTds',
    authDomain: 'shortflix-v2.firebaseapp.com',
    databaseURL: 'https://shortflix-v2.firebaseio.com',
    projectId: 'shortflix-v2',
    storageBucket: 'shortflix-v2.appspot.com',
    messagingSenderId: '896276958287',
    appId: '1:896276958287:web:18c816658fe37967572e3a',
    measurementId: 'G-2T0HH0XMTW'
  })
  useEffect(() => {
    onAuthStateChanged(firebase.auth(), (user) => {
      try {
        if (user) {
          const data = CryptoJS.AES.encrypt(
            JSON.stringify(user),
            process.env.REACT_APP_SECERET_KEY
          ).toString();
          // console.log("data", data)
          localStorage.setItem('usermail',user.email)
          localStorage.setItem('userKey', data);
        } else {
          localStorage.removeItem('userKey');
        }
      } catch (e) {
        //alert(e.message)
      }

    })
  })
  let location = useLocation();
  
  return (
    <div className="App">
      {!location.pathname.startsWith('/about') && !location.pathname.startsWith('/privacy') && !location.pathname.startsWith('/terms') ? <Header /> : ''}
      <AppRoute />
      {!location.pathname.startsWith('/about') && !location.pathname.startsWith('/privacy') && !location.pathname.startsWith('/terms') ? <Footer /> : ''}
      <ToastContainer />
      <AppLoader />
    </div>
  )
}

export default App;
