import React, { Component } from 'react';
import './home.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination,Autoplay } from "swiper/modules";
import Card from '../../components/card/Card';
import ApiServices from '../../services/apiServices';
import { connect } from "react-redux";
import CryptoJS from 'crypto-js';
import withRouter from '../../services/withRouter';
import { showLoader, hideLoader, setUrl, PreviousUrl } from "../../action/application";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resume: {},
      memberShip: false,
      bannerData: [],
      latestMovies: [],
      loader: false,
      moviesList: [],
      version_map: {
        "2.0.0": {
          "getMovieList": "movies/list?limit=2",
          "getHomeBanner": "movies/banner",
          "getLatestMovies": "movies/latest",
          "getRecomendation": "movies/recommendation"
        },
        "2.1.52": {
          "getMovieList": "movies/sflist/web?limit=2",
          "getHomeBanner": "movies/banner/tv",
          "getLatestMovies": "movies/sflist/tv",
          "getRecomendation": "movies/recommendation/web"
        }
      }
    };
  }

  componentDidMount() {
   
    this.props.dispatch(showLoader());
    console.log('windows', window.location.pathname);

    if (localStorage.getItem('path') && localStorage.getItem('path').startsWith('/tv') && window.location.pathname !== '/') {
      const path = localStorage.getItem('path');
      localStorage.removeItem('path');
      window.location.href = path;
    } else {
      localStorage.removeItem('path');
      this.checkMembership();
      this.getHomeBanner();
      this.getLatestMovies();
    }
  }

  async getHomeBanner() {
    try {
      const result = await ApiServices.callGetAPI(this.state.version_map[process.env.REACT_APP_VERSION]['getHomeBanner']);
      if (result.status === 200 && result.data.valid) {
        this.setState({ bannerData: result.data.data[0].movies });
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_RETRYV2 === "yes") {
        this.retryHomeBanner();
      }
    }
  }

  async retryHomeBanner() {
    try {
      const result = await ApiServices.callGetAPI(this.state.version_map[process.env.REACT_APP_VERSION_V2]['getHomeBanner']);
      if (result.status === 200 && result.data.valid) {
        this.setState({ bannerData: result.data.data[0].movies });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getLatestMovies() {
    try {
      const result = await ApiServices.callGetAPI(this.state.version_map[process.env.REACT_APP_VERSION]['getLatestMovies']);
      if (result.status === 200 && result.data.valid) {
        this.setState({ latestMovies: result.data.data });
        localStorage.removeItem('reloadcount', 0);
        if (localStorage.getItem('userKey')) {
          const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
          const userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          //this.getRecomendation(userInfo.uid);
        }
      }
      this.props.dispatch(hideLoader());
    } catch (error) {
      this.handleLatestMoviesError(error);
    }
  }

  async handleLatestMoviesError(error) {
    let reloadcount = parseInt(localStorage.getItem('reloadcount') || 0) + 1;
    localStorage.setItem('reloadcount', reloadcount);
    console.log("reloadcount", reloadcount);
    if (reloadcount < 6) {
      setTimeout(() => window.location.reload(), 1500);
      return;
    }
    console.log(error);
    if (process.env.REACT_APP_RETRYV2 === "yes") {
      this.retryLatestMovies();
    } else {
      this.props.dispatch(hideLoader());
    }
  }

  async retryLatestMovies() {
    try {
      const result = await ApiServices.callGetAPIV2(this.state.version_map[process.env.REACT_APP_VERSION_V2]['getLatestMovies']);
      if (result.status === 200 && result.data.valid) {
        this.setState({ latestMovies: result.data.data });
        if (localStorage.getItem('userKey')) {
          const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY_V2);
          const userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          this.getRecomendation(userInfo.uid);
        } else {
          this.getMovieList();
        }
      }
      this.props.dispatch(hideLoader());
    } catch (error) {
      console.log(error);
      this.props.dispatch(hideLoader());
    }
  }

  async checkMembership() {
    try {
      const result = await ApiServices.callGetAPI('membership/validate');
      if (result.status === 200 && result.data.valid) {
        this.setState({ memberShip: result.data.data.premiumuser === 1 });
      } else {
        this.setState({ memberShip: false });
      }
      localStorage.removeItem('reloadcount', 0);
    } catch (error) {
      console.log(error);
      const userToken = localStorage.getItem('userKey');
      if (userToken) {
        let reloadcount = parseInt(localStorage.getItem('reloadcount') || 0) + 1;
        localStorage.setItem('reloadcount', reloadcount);
        console.log("reloadcount", reloadcount);
        if (reloadcount < 6) {
          setTimeout(() => window.location.reload(), 1500);
          return;
        }
      }
      this.setState({ memberShip: false });
    }
  }

  watchvideo(movieData) {
    this.props.dispatch(setUrl(movieData));
    this.props.dispatch(PreviousUrl(window.location.pathname));
    const url = movieData.type === 'Film' ? `/watch/movie/${movieData.filmid}` : `/watch/series/${movieData.filmid}/${this.state.resume.episode || "short1"}`;
    this.props.navigate(url);
  }

  buyPlans() {
    this.props.dispatch(PreviousUrl(window.location.pathname));
    this.props.navigate('/subscricption');
  }

  removeMovieFromBanner(xindex, xfilmid) {
    const bannerData = [...this.state.bannerData];
    if (bannerData[xindex]?.filmid === xfilmid) {
      bannerData.splice(xindex, 1);
      this.setState({ bannerData });
    }
  }

  render() {
    return (
      <div className='page container'>
        <div className='banner'>
          <Swiper
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            speed={1200}
            loop={true}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            className="mySwiper"
          >
            {this.state.bannerData.map((banner, i) => (
              <SwiperSlide key={i}>
                <img
                  alt=""
                  src={banner.banner[0].split("-tv-").join("-").split("-undefined-").join("-").split(".png").join(".webp")}
                  className="bannerimage"
                  onError={({ currentTarget }) => this.removeMovieFromBanner(i, banner.filmid)}
                />
                <div className='xbnrdetail-small' style={{ display: "none", position: "relative" }}>
                  <div className='main-body bnrdetail-small-top flex'>
                    <div className='bnrdetail-small-top-left' style={{ textAlign: "center" }}>
                      <p>
                        <span className="badge plain medium" style={{ color: "grey" }}>{banner.bannertext}</span>
                      </p>
                      <div className='cta flex'>
                        {this.state.memberShip && (
                          <button
                            className='button flex align-items-center justify-content-center'
                            onClick={() => this.watchvideo(banner)}
                          >
                            {this.state.resume.duration && banner.display_type !== 'Coming Soon'
                              ? (this.state.resume.episode ? 'Resume-E' + this.state.resume.episode.replace('short', '') : 'Resume')
                              : 'Watch Now'}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className='bnrdetail-small-top-right flex'>
                      <span className='card-thumb skeleton-animation skeleton-card' ></span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className='main-body'>
          {this.state.latestMovies.map((movieList, i) => (
            <Card key={i} cardType={movieList.displayOrder === 3 ? 'continue' : 'video'} cardData={movieList} />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ loading: state.application.loading });

export default withRouter(connect(mapStateToProps)(Home));
