import React from 'react'
import './footer.scss'
import logo from '../../assets/images/logo.png';
import andriod from '../../assets/images/google-play.png';
import iphone from '../../assets/images/app-store.png';
import androidtv from '../../assets/images/androidtv.png';
import firetv from '../../assets/images/firetv.png';
import appletv from '../../assets/images/appletv.png';
import jiotv from '../../assets/images/jiotv.png';
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer className='footer'>
      <div className='footer-list flex justify-content-between'>
        <a href="/" className='footer-logo flex align-items-left justify-content-left'><img src={logo} className="App-logo" alt="logo" /></a>
        <div className='footer-link social Column1'>
          <h4></h4>
          <ul className='footer-menus'>
            {/* <li className='footer-menu'><Link to={'/subscricption'}>Pricing</Link></li> */}
            <li className='footer-menu'><Link to={'/about'}>About Us</Link></li>
            <li className='footer-menu'><Link to={'/terms'}>Terms and Conditions</Link></li>
            <li className='footer-menu'><Link to={'/privacy'}>Privacy Policy</Link></li>
          </ul>
          <h4>Connect With Us</h4>
          <ul className='connect flex align-items-center'>
            <li>
              <a target="_blank" href="https://www.facebook.com/shortFlixindia?mibextid=LQQJ4d" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="36px" height="36px"><linearGradient id="Ld6sqrtcxMyckEl6xeDdMa" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#2aa4f4" /><stop offset="1" stopColor="#007ad9" /></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z" /><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z" /></svg>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://instagram.com/shortflixindia?igshid=YmMyMTA2M2Y=" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="36px" height="36px"><radialGradient id="yOrnnhliCrdS2gy~4tD8ma" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#fd5" /><stop offset=".328" stopColor="#ff543f" /><stop offset=".348" stopColor="#fc5245" /><stop offset=".504" stopColor="#e64771" /><stop offset=".643" stopColor="#d53e91" /><stop offset=".761" stopColor="#cc39a4" /><stop offset=".841" stopColor="#c837ab" /></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z" /><radialGradient id="yOrnnhliCrdS2gy~4tD8mb" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#4168c9" /><stop offset=".999" stopColor="#4168c9" stopOpacity="0" /></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z" /><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z" /><circle cx="31.5" cy="16.5" r="1.5" fill="#fff" /><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z" /></svg>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/shortflixindia?s=21&t=vrybLQSIspAxLOsIeK6ojQ" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="36px" height="36px"><linearGradient id="_osn9zIN2f6RhTsY8WhY4a" x1="10.341" x2="40.798" y1="8.312" y2="38.769" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#2aa4f4" /><stop offset="1" stopColor="#007ad9" /></linearGradient><path fill="url(#_osn9zIN2f6RhTsY8WhY4a)" d="M46.105,11.02c-1.551,0.687-3.219,1.145-4.979,1.362c1.789-1.062,3.166-2.756,3.812-4.758	c-1.674,0.981-3.529,1.702-5.502,2.082C37.86,8.036,35.612,7,33.122,7c-4.783,0-8.661,3.843-8.661,8.582	c0,0.671,0.079,1.324,0.226,1.958c-7.196-0.361-13.579-3.782-17.849-8.974c-0.75,1.269-1.172,2.754-1.172,4.322	c0,2.979,1.525,5.602,3.851,7.147c-1.42-0.043-2.756-0.438-3.926-1.072c0,0.026,0,0.064,0,0.101c0,4.163,2.986,7.63,6.944,8.419	c-0.723,0.198-1.488,0.308-2.276,0.308c-0.559,0-1.104-0.063-1.632-0.158c1.102,3.402,4.299,5.889,8.087,5.963	c-2.964,2.298-6.697,3.674-10.756,3.674c-0.701,0-1.387-0.04-2.065-0.122C7.73,39.577,12.283,41,17.171,41	c15.927,0,24.641-13.079,24.641-24.426c0-0.372-0.012-0.742-0.029-1.108C43.483,14.265,44.948,12.751,46.105,11.02" /></svg>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://youtube.com/@ShortFlix" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="36px" height="36px"><linearGradient id="PgB_UHa29h0TpFV_moJI9a" x1="9.816" x2="41.246" y1="9.871" y2="41.301" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#f44f5a" /><stop offset=".443" stopColor="#ee3d4a" /><stop offset="1" stopColor="#e52030" /></linearGradient><path fill="url(#PgB_UHa29h0TpFV_moJI9a)" d="M45.012,34.56c-0.439,2.24-2.304,3.947-4.608,4.267C36.783,39.36,30.748,40,23.945,40	c-6.693,0-12.728-0.64-16.459-1.173c-2.304-0.32-4.17-2.027-4.608-4.267C2.439,32.107,2,28.48,2,24s0.439-8.107,0.878-10.56	c0.439-2.24,2.304-3.947,4.608-4.267C11.107,8.64,17.142,8,23.945,8s12.728,0.64,16.459,1.173c2.304,0.32,4.17,2.027,4.608,4.267	C45.451,15.893,46,19.52,46,24C45.89,28.48,45.451,32.107,45.012,34.56z" /><path d="M32.352,22.44l-11.436-7.624c-0.577-0.385-1.314-0.421-1.925-0.093C18.38,15.05,18,15.683,18,16.376	v15.248c0,0.693,0.38,1.327,0.991,1.654c0.278,0.149,0.581,0.222,0.884,0.222c0.364,0,0.726-0.106,1.04-0.315l11.436-7.624	c0.523-0.349,0.835-0.932,0.835-1.56C33.187,23.372,32.874,22.789,32.352,22.44z" opacity=".05" /><path d="M20.681,15.237l10.79,7.194c0.689,0.495,1.153,0.938,1.153,1.513c0,0.575-0.224,0.976-0.715,1.334	c-0.371,0.27-11.045,7.364-11.045,7.364c-0.901,0.604-2.364,0.476-2.364-1.499V16.744C18.5,14.739,20.084,14.839,20.681,15.237z" opacity=".07" /><path fill="#fff" d="M19,31.568V16.433c0-0.743,0.828-1.187,1.447-0.774l11.352,7.568c0.553,0.368,0.553,1.18,0,1.549	l-11.352,7.568C19.828,32.755,19,32.312,19,31.568z" /></svg>
              </a>
            </li>
          </ul>
        </div>
        {/* <div className='footer-link social categories'>
          <h4>Categories</h4>
          <ul className='footer-menus'>
            <li className='footer-menu'><Link to={'/'}>Movies</Link></li>
            <li className='footer-menu'><Link to={'/'}>Web Series</Link></li>
          </ul>
        </div> */}
        <div className='footer-link menu Column3'>
          <h4>Contact Information</h4>
          <ul className='footer-menus'>
            <li className='footer-menu'>
              <svg style={{ marginLeft: "-12px", marginBottom: "-5px", marginTop: "5px" }} width="48px" height="20px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="0" y="0" width="24.00" height="24.00" rx="0" fill="transparent" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="style=fill"> <g id="email"> <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M7 2.75C5.38503 2.75 3.92465 3.15363 2.86466 4.1379C1.79462 5.13152 1.25 6.60705 1.25 8.5V15.5C1.25 17.393 1.79462 18.8685 2.86466 19.8621C3.92465 20.8464 5.38503 21.25 7 21.25H17C18.615 21.25 20.0754 20.8464 21.1353 19.8621C22.2054 18.8685 22.75 17.393 22.75 15.5V8.5C22.75 6.60705 22.2054 5.13152 21.1353 4.1379C20.0754 3.15363 18.615 2.75 17 2.75H7ZM19.2285 8.3623C19.5562 8.10904 19.6166 7.63802 19.3633 7.31026C19.1101 6.98249 18.6391 6.9221 18.3113 7.17537L12.7642 11.4616C12.3141 11.8095 11.6858 11.8095 11.2356 11.4616L5.6886 7.17537C5.36083 6.9221 4.88982 6.98249 4.63655 7.31026C4.38328 7.63802 4.44367 8.10904 4.77144 8.3623L10.3185 12.6486C11.3089 13.4138 12.691 13.4138 13.6814 12.6486L19.2285 8.3623Z" fill="#ffffff"></path> </g> </g> </g></svg>
              <a href="mailto:info@shortflixindia.com" target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>
                care@shortflixindia.com
              </a>
            </li>
            {/* <li className='footer-menu'>
              <Link to={'/about'}>
                <svg style={{ marginLeft: "-12px", marginBottom: "-5px", marginTop: "5px" }} width="48px" height="20px" viewBox="-1.8 -1.8 21.60 21.60" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-1.8" y="-1.8" width="21.60" height="21.60" rx="0" fill="transparent" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 7.184C16 3.14 12.86 0 9 0S2 3.14 2 7c-1.163.597-2 1.696-2 3v2a3 3 0 0 0 3 3h1a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1 5 5 0 0 1 10 0 1 1 0 0 0-1 1v6a1 1 0 0 0 1 1v1h-2.592c-.206-.581-.756-1-1.408-1H8a1.5 1.5 0 0 0 0 3h6a2 2 0 0 0 2-2v-1.183A2.992 2.992 0 0 0 18 12v-2a2.99 2.99 0 0 0-2-2.816L-7 62" fill="#ffffff" fill-rule="evenodd"></path> </g></svg>
                Contact Us
              </Link></li> */}
          </ul>
        </div>
        <div className='footer-link app'>
          <h4>App Stores</h4>
          <ul className='download flex align-items-center'>
            <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.shortflix" rel="noreferrer"><img src={andriod} alt="download-andriod"  width="120px" height="34px" style={{border:"1px solid grey",width: "115px",height: "34px", margin:"0px 0px 0px 3px"}}/></a></li>
            <li><a target="_blank" href="https://apps.apple.com/in/app/shortflix/id1448385291" rel="noreferrer"><img src={iphone} alt="download-iphone"  width="120px" height="34px" style={{border:"1px solid grey",width: "117px",height: "34px", margin:"0px 0px 0px 3px"}}/></a></li>
          </ul>
          <ul className='download flex align-items-center'>
            <li><img src={androidtv} alt="download-andriodtv" width="120px" height="36px" /></li>
            <li><img src={firetv} alt="download-firetv" width="120px" height="36px" /></li>
          </ul>
          <ul className='download flex align-items-center'>
            <li><img src={appletv} alt="download-appletv" width="120px" height="36px" /></li>
            <li><img src={jiotv} alt="download-jiotv" width="120px" height="36px" /></li>
          </ul>
        </div>
      </div>
      <div className='footer-list flex justify-content-center'>
        <span>Copyright @ 2024 ShortFlix.</span>
      </div>
      <div className='footer-list flex justify-content-center'>
        <span>Powered by Vaibhav Visuals India Private Limited.</span>
      </div>
    </footer>
  )
}

export default Footer