import React from 'react'
import './about.scss'
import logo from '../../assets/images/logo.png';
function AboutIos() {
  window.scrollTo(0, 0);
  return (
    <>
      <header className='header flex align-items-center justify-content-between'>
        <div className='heder-left flex align-items-center'>
          <a href="/" className='flex'><img src={logo} className="App-logo" alt="logo" /></a>
        </div>
      </header>
      <div className='main-body about-body'>
        <h2>
          ABOUT US</h2>
        <p>
          Welcome to ShortFlix!</p>
        <p>
          Exclusive OTT platform for OTT Movies / Web Series / Short films. ShortFlix is your one-stop destination for original and award-winning exclusive short-format content.</p>
        <p>
          Watch any Short Film, Series, or OTT Movie anywhere and anytime on the go. We make your watching experience even easier with our short descriptions and categorization based on genre.</p>
        <p>
          Plus, with more and more amazing content added all the time, you'll never run out of films to watch.</p>
        <h3>
          UPLOAD PROCESS</h3>

        <p>
          Sharing your content is a very easy process. Reach us through email  info@shortflixindia.com we will assist you.
        </p>
        <p>
          Our creative team will review your content and if it is approved by our creative team then we will stream your content in our ShortFlix OTT app for the viewers across the world.
        </p>

        <h3>
          CONTACT</h3>

        <p>Website:<a href="https://www.shortflixindia.com/"> www.shortflixindia.com</a></p>
        <p>For Complaints:<a href="mailto:care@shortflixindia.com" target="_blank" rel="noreferrer"> care@shortflixindia.com</a> </p>
        <p>For Enquiries:<a href="mailto:info@shortflixindia.com" target="_blank" rel="noreferrer"> info@shortflixindia.com</a> </p>
      </div>
    </>
  )
}

export default AboutIos