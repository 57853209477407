import React, { useState, useEffect } from 'react';
import './accountSettings.scss';
import profile from '../../assets/images/profile.svg';
import CryptoJS from 'crypto-js';
import ApiServices from '../../services/apiServices';
import firebase from 'firebase/compat/app';
import { Link, useNavigate } from "react-router-dom";

const AccountSettings = () => {
  const [userInfo, setUserInfo] = useState({});
  const [userPlan, setUserPlan] = useState({});
  const [memberShip, setMemberShip] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('userKey')) {
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
      const userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log('userInfo', userInfo);
      setUserInfo(userInfo);
      checkMembership();
    }
  }, []);

  const checkMembership = async () => {
    try {
      const result = await ApiServices.callGetAPI('membership/validate');
      if (result.status === 200 && result.data.valid) {
        setMemberShip(result.data.data.premiumuser);
        setUserPlan(result.data.data);
      } else {
        setMemberShip(false);
      }
    } catch (error) {
      console.log(error);
      setMemberShip(false);
    }
  };

  const handleDeleteAccount = () => {
    navigate('/delete-account');
  };
  const logout=()=>{
    firebase.auth().signOut()
    navigate('/')

  }

  return (
    <div className="acc-body">
      <div className="acc-body-inner card">
        <h1>Account & Settings</h1>
        <div className="profile-section">
          <img
            src={userInfo.photoURL || profile}
            className="profile-pic"
            alt="profile pic"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = profile;
            }}
          />
          <h2>{userInfo.displayName || 'No Name Provided'}</h2>
          <p>{userInfo.email || 'No Email Provided'}</p>
          <p>{userInfo.phoneNumber || 'No Phone Number'}</p>
        </div>

        {memberShip && userPlan ? (
          <div className="plan-section">
            <p className="heading">
              Thank you for being a premium member. You are on the <span className="plan-details">{userPlan.membershiptype}</span> plan.
            </p>
            <p>Start Date: <span className="plan-details">{userPlan.starton}</span></p>
            <p>End Date: <span className="plan-details">{userPlan.endon}</span></p>
            <p className="heading">Your subscription will expire in <span className="plan-details">{userPlan.opendays}</span> days.</p>
          </div>
        ) : (
          <button className="button subscribe-button">
            <Link to="/subscricption">Subscribe</Link>
          </button>
        )}

        <button className="button logout-button" onClick={logout}>Logout</button>

        <button className="button delete-button" onClick={handleDeleteAccount}>
          Delete Account
        </button>
      </div>
    </div>
  );
};

export default AccountSettings;
